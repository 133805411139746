import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Container, Flex, Grid, Text, Link } from "@chakra-ui/react";

export default function FAQ() {
    return <Container maxW={'90%'} py={[10, 10, 150]} >
        <Grid templateColumns={["1fr", "1fr", "repeat(2, 1fr)"]} // On mobile, each feature takes full width, on large screens, it's 2 columns
            gap={10} w={'100%'}>
            <Text as={'h3'} fontSize={[28, 28, 66]} fontWeight={300}>
                <Text fontWeight={600}>
                    Frequently Asked
                </Text>
                Questions
            </Text>
            <Flex alignItems={'center'}>
                <Accordion color={'green.900'} allowToggle w={'100%'}>
                    <AccordionItem bg={'white'} borderRadius={8}>
                        <h2>
                            <AccordionButton py={5}>
                                <Text as='span' flex='1' textAlign='left' fontWeight={600}>
                                    Is LandDAO a decentralized organization?
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            Yes, LandDAO is a DAO with no management team members. It only has project contributors. The set up of the fully functional DAO is to be completed with release of LandDAO 2.0
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem mt={5} bg={'white'} borderRadius={8}>
                        <h2>
                            <AccordionButton py={5}>
                                <Text as='span' flex='1' textAlign='left' fontWeight={600}>
                                    Are people from every country allowed to buy land on LandDAO?
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            No. Residents and citizens of certain countries are not permitted to use LandDAO; USA, North Korea, Iran and Somalia.
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem mt={5} bg={'white'} borderRadius={8}>
                        <h2>
                            <AccordionButton py={5}>
                                <Text as='span' flex='1' textAlign='left' fontWeight={600}>
                                    I have land to sell. How can I contact LandDAO team?
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            Fill out this form out and we will contact you: <Link href="https://docs.google.com/forms/d/e/1FAIpQLSddLtsv6B9R_ZrtpW4GZrtNkU4y4MZWYI7CsbsHyozgmq86zw/viewform" color="blue.500" textDecoration="underline" isExternal>LandDAO Land Seller Form</Link>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Grid>
    </Container>
}