import { Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";
import api from "api";
import { memo, useEffect, useState } from "react";
import { BACKEND_DOMAIN } from "utils/constants";

function ExecutiveSummay() {

    const [file, setFile] = useState(null)

    useEffect(() => {
        api.files().then(x => {
            const data = x.data?.filter(x => x.file_type === "ES")
            if(data.length) {
                setFile(data[0])
            }
        }).catch(e => {
            if (e.response) {
                console.log(e.response)
            } else {
                console.error(e)
            }
        })
    }, [])

    return <Flex
        bg="green.300"
        color={'green.900'}
        p={[10, 10, 100]}
        flexDir="column"
        alignItems={'center'}
    >
        <Image src="/images/landing_summary_icon.webp" h={206} w={206} />
        <Stack direction={["column", "column", "row"]} alignItems="center" fontSize={[28, 28, 66]} fontWeight={300}>
            <Text as="span" fontWeight={600}>
                Executive
            </Text>{" "}
            <Text>
                Summary
            </Text>
        </Stack>
        {file && <Button as={Link} href={`${BACKEND_DOMAIN}/content/${file.file}`}
            target='_blank'
            borderRadius={0}
            mt={10}
            bg={'green.900'}
            color='white'
            _hover={{ bg: 'green.400' }}
            size={'lg'}>View Document</Button>}
    </Flex>
}

export default memo(ExecutiveSummay)