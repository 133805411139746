import { Box, Flex, Image, Text } from "@chakra-ui/react";
import SocialMediaLinks from "./components/SocialMediaLinks";

export default function Footer() {
  return <footer>
    <Flex flexDir={'column'} bg={'green.900'} pb={14}>
      <Flex justifyContent={'space-between'} px={[4, 4, 10]} alignItems={'center'}>
        {/* Your header content goes here */}
        <Box p={4}>
          <Image src={'/logo.png'} height={['45px', '45px', '60px']} w={[100, 100, 160]} />
        </Box>
        <Flex alignItems="center">
          <SocialMediaLinks />
        </Flex>
      </Flex>
      <Text textAlign={'center'} color={'green.500'} fontSize={[12, 12, 14]}>For inquiries email our team: contact@landdao.io</Text>

      <Text textAlign={'center'} color={'green.500'} fontSize={[12, 12, 14]}>Copyright ©2025 LandDAO, All rights reserved.</Text>

    </Flex>
  </footer>
}